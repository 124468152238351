import axiosClient from "config/axiosClient";

/* eslint-disable default-case */
const { useReducer, createContext, useEffect } = require("react");
export const localContext = createContext();

const localTypes = {
  GET_LOCALS: "GET_LOCALS",
  SELECT_LOCAL: "SELECT_LOCAL",
};

const getFriendlyLocals = (locals) => {
    const friendlyLocals = {}
    locals.forEach(local => {
      friendlyLocals[local._id] = local.name;
    })
    return friendlyLocals;
}

const localReducer = (state, action) => {
  switch (action.type) {
    case localTypes.GET_LOCALS:
      return {
        ...state,
        locals: action.payload,
        friendlyLocals: getFriendlyLocals(action.payload)
      };
    case localTypes.SELECT_LOCAL:
      return {
        ...state,
        localSelected: action.payload,
      };
  }
};

const LocalState = ({ children }) => {
  const initialState = {
    locals: [],
    localSelected: "",
    friendlyLocals: {},
  };

  const [state, dispatch] = useReducer(localReducer, initialState);

  const getLocals = async () => {
    try {
      const { data: locals } = await axiosClient.get("/local");
      dispatch({ type: localTypes.GET_LOCALS, payload: locals });
    } catch (error) {}
  };

  const selectLocal = async (local) => {
    try {
      dispatch({ type: localTypes.SELECT_LOCAL, payload: local });
    } catch (error) {}
  };

  useEffect(() => {
    getLocals();
  }, []);

  return (
    <localContext.Provider
      value={{
        locals: state.locals,
        localSelected: state.localSelected,
        friendlyLocals: state.friendlyLocals,
        selectLocal,
      }}
    >
      {children}
    </localContext.Provider>
  );
};

export default LocalState;
