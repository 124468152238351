import ProductTable from "components/product-table/ProductTable";
import SearchBar from "components/search-bar/SearchBar";
import productContext from "context/product/productContext";
import React, { useContext, useEffect, useState } from "react";
import useFilter from "hooks/useFilter";
import CategoryFilter from "components/category-filter/CategoryFilter";
import categoryContext from "context/category/categoryContext";
import "./filterabletable.scss";
import { localContext } from "context/local/localState";

const FilterableProductTable = () => {
  const { products } = useContext(productContext);
  const { categories } = useContext(categoryContext);
  const { localSelected } = useContext(localContext);
  const [productsLocal, setProductsLocal] = useState(products);
  const [filteredProducts, searchText, setSearchText] = useFilter({
    items: productsLocal,
    filterFields: [
      "name",
      "description",
      { field: "category", subField: "name" },
    ],
  });
  const isSearchTextEmpty = searchText === "";

  const handleSelectCategory = (category) => {
    setSearchText(category.name);
  };

  useEffect(() => {
    if (localSelected === "") {
      setProductsLocal(products);
      return;
    }
    const productsByLocal = products?.filter((product) =>
      product.local.includes(localSelected)
    );
    setProductsLocal(productsByLocal);
  }, [localSelected]);

  useEffect(() => {
    setProductsLocal(products);
  }, [products]);

  return (
    <div className="filterable-product-table">
      <SearchBar searchText={searchText} setSearchText={setSearchText} />
      <CategoryFilter onClick={handleSelectCategory} searchText={searchText} />
      {!isSearchTextEmpty && (
        <p className="text-result">
          {filteredProducts.length} resultado
          {filteredProducts.length === 1 ? "" : "s"}
        </p>
      )}
      <ProductTable products={filteredProducts} categories={categories} />
    </div>
  );
};

export default FilterableProductTable;
