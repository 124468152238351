import ProductCategory from 'components/product-category/ProductCategory';
import ProductRow from 'components/product-row/ProductRow';
import React from 'react';

const ProductCategoryCard = ({productCategory}) => {
    return (  
        <div className="product-category-card">
            <ProductCategory 
                name = {productCategory.category.name}
                image = {productCategory.category.image}
            />
            <ProductRow 
                products = {productCategory.products}
            />
        </div>
    );
}
 
export default ProductCategoryCard;