import React from "react";
import "./footer.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <p className="copy-right"> {currentYear} © Todos los derechos reservados </p>
        <p>
          Desarrollado por{" "}
          <a
            href="https://api.whatsapp.com/send?phone=5492215060652"
            target="_blank"
          >
            @forkdeveloper
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
