import React, { useEffect, useState } from 'react';
import "./priceRate.scss";
import axiosClient from 'config/axiosClient';

const PriceRate = () => {
    const [ratePrice, setRatePrice] = useState("");

    useEffect(() => {
        const getRatePrice = async () => {
            // const res = await axios.get('https://exchange.vcoud.com/coins/latest?type=bolivar&base=usd');
            // const dolarBcv = res.data.filter(item =>  item.slug === 'dolar-bcv')[0].price;
            const res = await axiosClient.get('/metadata/tasa-bcv');
            const dolarBcv = res.data.value;
            setRatePrice(dolarBcv)
        };
        getRatePrice();
    }, []);
    return ( 
        <section className='price-rate p03'>
            <p>ESTOS PRECIOS INCLUYEN EL IVA</p>
            <p>NO COBRAMOS EL 10% DEL SERVICIO</p>
            <span className='rate-title'>TASA BCV</span>
            <span className='rate-value'>{ratePrice}</span>
        </section>
     );
}
 
export default PriceRate;