import React from "react";
import leafBig from "assets/images/leaf-big.png";
import leafSmall from "assets/images/leaf-small.png";
import "./background.scss"

const BackgroundLayout = ({ children }) => {
  return (
    <div className="background-layout">
      <div className="background">
        {/* <img src={leafBig} alt="" className="leaf-big" />
        <img src={leafSmall} alt="" className="leaf-small" /> */}
      </div>
      <div className="background-layout__content">{children}</div>
    </div>
  );
};

export default BackgroundLayout;
