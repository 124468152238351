import { localContext } from "context/local/localState";
import { useContext } from "react";
import "./localSelect.scss";

const LocalSelect = () => {
  const { locals, selectLocal, localSelected } = useContext(localContext);
  const handleChange = (e) => {
    const value = e.target.value;
    selectLocal(value);
  };

  return (
    <section className="local-select">
      <select onChange={handleChange} value={localSelected}>
        <option value={""} className="local-select__default">Todos los locales</option>
        {locals.map((local) => (
          <option value={local._id}>{local.name}</option>
        ))}
      </select>
    </section>
  );
};

export default LocalSelect;
