import React from 'react';
import "./searchbar.scss"
import rodeoIcon from "assets/images/rodeoIcon.png";

const SearchBar = ({searchText, setSearchText}) => {
    return (  
        <div className="search-bar">
            <div className='search-input input'>
                <img src={rodeoIcon} alt="rodeoIcon" className="search-input__rodeo-icon"/>
            <input 
                type="text" 
                placeholder="¿Qué quieres comer?" 
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
            </div>
        </div>
    );
}
 
export default SearchBar;