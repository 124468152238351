import ProductItem from 'components/product-item/ProductItem';
import React from 'react';
import './productrow.scss'

const ProductRow = ({products}) => {
    return (  
        <div className="products-row">
            {products.map(product => (
                <ProductItem
                    product={product}
                    key={product._id}
                />
            ))}
        </div>
    );
}
 
export default ProductRow;