import GoTopSvg from "assets/svg/GoTopSvg";
import React, { useState, useEffect } from "react";
import "./scrollTopButton.scss";

const ScrollTopButton = () => {
  const [show, setShow] = useState(false);
  let lastScrollTop = 0;

  const handleScroll = () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) setShow(true);
    else setShow(false);
   // hide button when scroll is at bottom
    if (st + window.innerHeight + 200 >= document.body.scrollHeight) {
      setShow(false);
    }

    if (st + window.innerHeight >= document.body.scrollHeight) {
      setShow(true);
    }

    lastScrollTop = st;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const showClass = show ? "show" : "";

  return (
    <div className={`scroll-top-button ${showClass}`}>
      <button onClick={handleScrollTop}>
        <GoTopSvg />
      </button>
    </div>
  );
};

export default ScrollTopButton;
