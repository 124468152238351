import React, { useEffect, useReducer } from "react";
import axiosClient from "config/axiosClient";
import categoryContext from "./categoryContext";
import categoryReducer from "./categoryReducer";


const CategoryState = (props) => {

    const initialState = {
        categories: []
    }

    const [state, dispatch] = useReducer(categoryReducer, initialState)
    const getCategories = async () => {
        try {
            const res = await axiosClient.get('/category')
            dispatch({
                type: 'GET_CATEGORIES',
                payload: res.data.docs
            })
        } catch (error) {
        }
    }
    useEffect(() => {
        getCategories()
    }, [])

    return (  
        <categoryContext.Provider value={{
            categories: state.categories,
        }}>
            {props.children}
        </categoryContext.Provider>
    );
}
 
export default CategoryState;