import React from "react";
import CategoryState from "context/category/categoryState";
import ProductState from "context/product/productState";
import RouterApp from "RouterApp";
import "./assets/css/App.scss";
import LocalState from "context/local/localState";

function App() {
  return (
    <div className="App">
      <LocalState>
        <CategoryState>
          <ProductState>
            <RouterApp />
          </ProductState>
        </CategoryState>
      </LocalState>
    </div>
  );
}

export default App;
