import Branding from "components/branding/Branding";
import { localContext } from "context/local/localState";
import { useContext, useEffect, useState } from "react";
import "./localSelectModal.scss";

const LocalSelectModal = () => {
  const { locals, selectLocal } = useContext(localContext);
  const [open, setOpen] = useState(false);
  const handleChange = (id) => {
    selectLocal(id);
    setOpen(false);
  };

  useEffect(() => {
      if (locals?.length > 0) {
          setOpen(true);
      }
  }, [locals])

  return (
    <section className={`local-select-modal ${open ? "open" : ""}`}>
      <div className="local-select-modal__container">
        <h3>¿En qué local te encuentras?</h3>
          <div className="local-select-modal__row">
            {locals.map((local) => (
              <section
                className="local-select-modal__card"
                onClick={() => handleChange(local._id)}
              >
                <Branding />
                <h4>{local.name}</h4>
              </section>
            ))}
        </div>
      </div>
    </section>
  );
};

export default LocalSelectModal;
