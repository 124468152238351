
export default (state, action) => {
    switch(action.type){
        case 'CREATE_CATEGORY': 
            return{
                ...state,
                categories: [action.payload, ...state.categories]
            }
        case 'UPDATE_CATEGORY':
            return{
                ...state,
                categories: state.categories.map(cat => cat._id === action.payload._id ? action.payload : cat)
            }
        case 'DELETE_CATEGORY':
            return{
                ...state,
                categories: state.categories.filter(cat => cat._id !== action.payload)
            }
        case 'GET_CATEGORIES':
            return{
                ...state,
                categories: action.payload
            }
        default:
            return state;
    }
}