import ProductCategoryCard from "components/product-category-card/ProductCategoryCard";
import React from "react";

const ProductTable = ({ products, categories }) => {
  const productCategories = [];
  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    const productsInCategory = products.filter(
      (product) => product.category._id === category._id
    );
    productCategories.push({
      category,
      products: productsInCategory,
    });
  }

  return (
    <div className="product-table small-column">
      {productCategories.map(
        (productCategory, i) =>
          productCategory.products.length > 0 && (
            <ProductCategoryCard
              key={`product-category-${i}`}
              productCategory={productCategory}
            />
          )
      )}
    </div>
  );
};

export default ProductTable;
