import categoryContext from "context/category/categoryContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { capitalize } from "utils/capitalize";
import "./categoryfilter.scss";

const CategoryFilter = ({ onClick, searchText }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const { categories } = useContext(categoryContext);
  //   const indicatorRef = useRef();
  const categoryRow = useRef();

  useEffect(() => {
    if (searchText === "") {
        setSelectedCategory("");
        categoryRow.current.scrollLeft = 0;
    }

      return () => {
      }
  }, [searchText])
  const handleClick = (category, i) => {
    if (category === "") {
      setSelectedCategory("");
      onClick({ name: "" });
      return;
    }
    setSelectedCategory(category._id);
    onClick(category);
    const rowContainer = categoryRow.current;
    const itemSelected = rowContainer.children[i];
    // move the scroll to the right by selecting the element closest to the end of the container and keeping it in the center of the container
    rowContainer.scrollLeft =
      itemSelected.offsetLeft -
      rowContainer.offsetWidth / 7 +
      (itemSelected.offsetWidth + 20) / 1;
  };

  const activeClass = (categoryId) => {
    return selectedCategory === categoryId ? "active" : "";
  };

  return (
    <div className="category-filter small-column">
      <div className="category-filter__row" ref={categoryRow}>
        <button
          className={`category-filter__item cls-btn-us ${activeClass("")}`}
          onClick={() => handleClick("", 0)}
        >
          <p>Todo</p>
        </button>
        {categories.map((category, i) => (
          <button
            className={`category-filter__item cls-btn-us ${activeClass(category._id)}`}
            key={category._id}
            onClick={() => handleClick(category, i)}
          >
            <p>{capitalize(category.name)}</p>
          </button>
        ))}
      </div>
      {/* <div className="category-filter__indicator" ref={indicatorRef}/> */}
    </div>
  );
};

export default CategoryFilter;
