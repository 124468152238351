import BackgroundLayout from 'components/background/BackgroundLayout';
import Branding from 'components/branding/Branding';
import FilterableProductTable from 'components/filterable-product-table/FilterableProductTable';
import Footer from 'components/footer/Footer';
import Loading from 'components/loading/Loading';
import LocalSelect from 'components/local-select/LocalSelect';
import LocalSelectModal from 'components/localSelectModal/LocalSelectModal';
import PriceRate from 'components/price-rate/PriceRate';
import ScrollTopButton from 'components/scroll-top-button/ScrollTopButton';
import productContext from 'context/product/productContext';
import React, { useContext, useEffect, useState } from 'react';

const Menu = () => {
    const [loading, setLoading] = useState(false);
    const { getProducts } = useContext(productContext);
    
    useEffect(() => {
       handleGetProducts();
    }, [])

    const handleGetProducts = async () => {
        setLoading(true);
        await  getProducts();
        setLoading(false)
    }
    return ( 
        <>
        {loading && <Loading />}
        <div className="App">
            <LocalSelectModal />
            <BackgroundLayout>
            <header>
            <Branding />
            <LocalSelect />
            </header>
            <FilterableProductTable />
            <PriceRate />
            <Footer />
            <ScrollTopButton />
            </BackgroundLayout>
        </div>
        </>
     );
}
 
export default Menu;