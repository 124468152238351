import HomePage from 'pages/Home/HomePage';
import Menu from 'pages/Menu';
import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
 
const RouterApp = () => {
    return (  
        <div className="App">
        <Router>
          <Routes>
            <Route exact  path="/" element={<HomePage />} />
            <Route exact  path="/menu-restaurant" element={<Menu />} />
          </Routes>
        </Router>
        </div>
    );
}
 
export default RouterApp;