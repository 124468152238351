import React from "react";
import "./loading.scss";
import isotipo from "assets/images/isotipo.png";

const Loading = () => {
  return (
    <div className="loader">
      <div id="cooking">
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div id="area">
          <div id="sides">
            <div id="pan"></div>
            <div id="handle"></div>
          </div>
          <div id="pancake">
            <div id="pastry">
              <img src={isotipo} id="logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
