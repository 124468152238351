import React, { useEffect, useReducer } from "react";
import productContext from "./productContext";
import productReducer from "./productReducer";
import axiosClient from "config/axiosClient";


const ProductState = (props) => {

    const initialState = {
        products: []
    }

    const [state, dispatch] = useReducer(productReducer, initialState)
    const getProducts = async () => {
        try {
            const res = await axiosClient.get('/product')
            dispatch({
                type: 'GET_PRODUCTS',
                payload: res.data.docs
            })

        } catch (error) {
        }
    }

    return (  
        <productContext.Provider value={{
            products: state.products,
            getProducts
        }}>
            {props.children}
        </productContext.Provider>
    );
}
 
export default ProductState;