import images from 'assets/images';
import React from 'react';
import "./branding.scss"

const Branding = () => {
    return ( 
        <div className="branding">
            <div className="branding__logo">
                <img src={images.logo} alt="Keep logo" />
            </div>
            {/* <div className="branding__text">
                <p>Busca en nuestro menú, por nombre, categoría, precio, etc...</p>
            </div> */}
        </div>
     );
}
 
export default Branding;