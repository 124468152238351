import { localContext } from "context/local/localState";
import React, { useContext } from "react";
import { capitalize } from "utils/capitalize";
import "./product.scss";

const ProductItem = ({ product }) => {
  const { friendlyLocals, locals } = useContext(localContext);
  const inAllLocals = locals?.length === product?.local.length;
  const isPaymentMethod = product?.category?.isPaymentMethod;
  return (
    <div className="product-item" key={product.id}>
      {product.image && product.image.trim() !== "" && (
        <div className="product-item__image">
          <img src={product.image} alt={product.name} />
        </div>
      )}
      <div className="product-item__text">
        <div className="product-item__left">
          <h2 className="product-item__name">
            {capitalize(product.name)} {product.soldOut && "(Agotado)"}
          </h2>
          <span className="product-item__description">
            {product.description}
          </span>
        </div>
        {!isPaymentMethod && (
          <p className="product-item__price">${product.price}</p>
        )}
      </div>
      <section className="product-locals">
        {!isPaymentMethod && (
          <>
            {inAllLocals ? (
              <section className="product-local-tag">
                En todos los locales
              </section>
            ) : (
              product?.local?.map((local) => (
                <section className="product-local-tag">
                  {friendlyLocals[local]}
                </section>
              ))
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default ProductItem;
