import React from "react";
import { httpToHttps } from "utils/httpToHttps";
import "./productcategory.scss";

const ProductCategory = ({ name, image }) => {
    image = httpToHttps(image);
  return (
    <div className="product-category">
      {image && image.trim() !== "" && (
        <div className="product-category__image">
          <img src={image} alt={name} width={"100%"} height={"auto"} />
        </div>
      )}
      <div className="product-category__text">
        <h1>{name}</h1>
      </div>
    </div>
  );
};

export default ProductCategory;
